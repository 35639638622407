module controllers {
    export module applicationcore {
        interface IIFrameScope extends ng.IScope {
            IFrameObject: IIFrameObject,
            iframeLoadedCallBack(element: angular.IRootElementService): void
        }

        interface IIFrameObject {
            url: string
        }

        interface IIFrameStateParams extends ng.ui.IStateParamsService {
            url: string
        }

        export class iFrameCtrl {

            static $inject = ["$scope", "$rootScope", "$location", "$stateParams", "$timeout", "bsLoadingOverlayService"];

            private tries: number = 0;
            private url: string;
            $urlParams: ng.ui.IUrlRouterService;

            constructor(private $scope: IIFrameScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public $location: ng.ILocationService,
                public $stateParams: IIFrameStateParams,
                public $timeout: ng.ITimeoutService,
                private bsLoadingOverlayService) {

                $scope.iframeLoadedCallBack = () => {
                    this.bsLoadingOverlayService.stop({
                        referenceId: "iframe"
                    });
                };

                this.$urlParams = $location.search();
                this.loadiFrame();
            }

            loadiFrame() {
                this.bsLoadingOverlayService.start({
                    referenceId: "iframe"
                });
                

                if (this.$rootScope.Entity) {
                    if (this.$stateParams.url) {
                        var url = decodeURIComponent(this.$stateParams.url);
                        var paramStringy: string = url.indexOf("?") > -1 ? "&" : "?";
                        this.$scope.IFrameObject = {
                            url: url + paramStringy + "securityToken=" + this.$rootScope.userToken + "&EntityId=" + this.$rootScope.Entity.Id
                        }
                    } else {
                        this.$scope.IFrameObject = {
                            url: "ApplicationCore/Default.aspx"
                        }
                    }
                } else if(this.tries <= 10) {
                    this.$timeout(() => {
                        this.tries++;
                        this.loadiFrame();
                    }, 500);
                }
            }
        };

        export class iframeOnload implements ng.IDirective {

            constructor() {

            }

            scope: {
                callBack: '&iframeOnload'
            }

            link = ($scope: IIFrameScope, element: angular.IRootElementService, attrs, ngModelController: ng.IController, $select) => {
                element.on('load', () => {
                    return $scope.iframeLoadedCallBack(element);
                });
            };

            static factory(): ng.IDirectiveFactory {
                const directive = () => new iframeOnload();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").controller("iFrameCtrl", controllers.applicationcore.iFrameCtrl);
        angular.module("app").directive("iframeOnload", iframeOnload.factory());
    }
}